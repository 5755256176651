import React from 'react'
import Navbar from 'react-bootstrap/Navbar'

const CoinsNavbar = () => {
  return (

    <>
      <Navbar bg="light" expand="lg" fixed="top">
        <Navbar.Brand href="/">COINs Personality Prediction</Navbar.Brand>

        <Navbar.Collapse id="basic-navbar-nav">

        </Navbar.Collapse>
      </Navbar>
    </>

  )
}
export default CoinsNavbar;

