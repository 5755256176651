import React from 'react'
import './PrivacyPolicy.css'
import './Footer'
import CoinsNavbar from './CoinsNavbar';





function PrivacyPolicy() {

    return (
        <div>
            <CoinsNavbar />
            <div className='datenschutz_position'>

            <h4>PRIVACY POLICY</h4> <br/> <h5>THE MOST IMPORTANT IN A NUTSHELL</h5>
<p>This website is provided with a TLS certificate, the data exchange with your browser is encrypted. This website can be visited without functional restrictions, even if your browser does not allow cookies. No functions are integrated into this website that allow conclusions to be drawn about your person. Your data will not be shared with parties outside Tim Sch&auml;fer &amp; without your express consent. A. RECOMMENDATION Log out of social media app and online accounts before visiting websites (e.g. YouTube, Facebook, LinkedIn, Instagram, Pinterest, Twitter, etc.). Otherwise, your surfing behavior will be recorded by the providers of these services and assigned to your profile. Without prior opt-out, these providers also record your visit to this website, although no connections to such providers are included on this website. B. DATA PROTECTION OFFICER Mr. Tim Sch&auml;fer, Subbelrather Stra&szlig;e 168, 50823 Cologne Contact</p>
<p>C. BRIEF CONCERNING THE HANDLING OF PERSONAL DATA Personal data will be deleted if no contractual relationship is established within a reasonable period of time, if warranty periods have expired or if you so request and if this request is not opposed by legal or judicial restrictions.</p>
<p>D. LEGAL We are obliged to protect personal data in accordance with Art. 13 of the Swiss Federal Constitution and the Swiss Federal Data Protection Act DSG and - where applicable - in accordance with the General Data Protection Regulation EU-DSGVO. We have taken all precautions and measures within our control and have entered into all possible agreements with external service providers so that we can guarantee the protection of this data online and offline to the best of our knowledge and belief.</p>
<p>E. TECHNICAL We have taken technical precautions to ensure that your visit to this website is as anonymous as possible and that your privacy is protected. This website complies with the requirements of "Data Protection by Design" and "Data Protection by Default".</p>
<p>In detail 1. VISITING OUR WEBSITE, SERVER LOGFILES During the connection of your browser with our website, the following information (server log files) is exchanged between your browser and our website hoster: Date and time of the call to our website The files called up (texts, graphics, photos, videos, etc.) The HTTP status of the request (file found, file not found, etc.) The browser you are using (e.g. Firefox. The browser version (e.g. 61.0.2) and browser settings (e.g. preferred language) The operating system used and its version (e.g. Windows 10, 64-bit version) The amount of data transmitted (e.g. 198,979 bytes) The requesting IP address (e.g. 188.60.244.121) This information is absolutely necessary for the IP connection setup, does not allow any conclusions to be drawn about you personally and is not evaluated by us. Our hoster stores this access data for as long as you access our website. If there is no legal obligation to retain the data, the log files are deleted immediately after your visit to the website ends or overwritten by more recent log files. What is an IP address?</p>
<p>2. ANALYSIS TOOLS This website uses the analysis tool Google Analytics (Google Marketing Platform). However, your IP address is usually anonymized within Switzerland/EU/EEA and only subsequently transferred to Google servers in the USA, we have concluded the corresponding contract with Google. Therefore, Google will most likely not learn about your visit to this website, unless you are logged into a personal Google account during your visit to this website (Google account "myaccount", YouTube, Google Maps, etc.).</p>
<p>Google Analytics records referring and direct accesses to this website as well as movement patterns within this website on our behalf and provides us with this data so that we can learn which websites link to our website, under which search terms this website was found on which search engines and how often which content pages were accessed. For this reason, Google Analytics sets both volatile cookies (session cookies) and persistent cookies when you visit this website. Volatile cookies are deleted again when you close the browser, while persistent cookies remain on your terminal device for a maximum period of 14 months.</p>
<p>We have configured Google Analytics as follows There are no links to other services of the 'Google Marketing Platform', for example to Google Ads. User recognition is deactivated. The function for recognition on websites other than this one is deactivated. If you never want to allow Google Analytics in principle, install the browser add-on to disable Google Analytics. Download: https://tools.google.com/dlpage/gaoptout</p>
<p>Additional information from Google about the Google Analytics analysis tool</p>
<p>To learn more about allowing, selectively allowing, preventing and deleting cookies, please read the file</p>
<p>What are cookies?</p>
<p>3. SCRIPT LIBRARIES JavaScript is used on this website. JavaScript are used to control visual and content functions, for example the expanding and collapsing question bars in the Digital Transformation chapter. JavaScript are embedded locally on this website, there are no external links to script libraries. Therefore, no operator of a script library learns about your visit to this website.</p>
<p>4. fONTS LIBRARIES Web fonts are displayed on this website. Web fonts are fonts that ensure a consistent appearance across all browsers. The web fonts we use are locally embedded, there are no external links to font libraries. Therefore, no operator of a font library learns about your visit to this website.</p>
<p>When you click on a link on website A to website B, browsers automatically send the Internet address of website A to the operator of website B. In this way, the operator of website B learns about your visit to this website. In this way, the operator of website B learns that a visitor to its website has clicked a link on website A. To protect your privacy, we have blocked this referral information from being forwarded to others on this website.</p>
<p>6. SHARE AND LIKE FUNCTIONS No share or like functions are included on this website. Nevertheless, please refer to section A. RECOMMENDATION.</p>
<p>7. COLLECTION AND HANDLING OF PERSONAL DATA When you ask us a question, request a quote from us or entrust us with an order, we collect the data necessary to respond to your request. This data will not be shared with parties outside COIN's seminar without your express consent. Personal data will be deleted if no contractual relationship is established within a reasonable period of time, warranty periods have expired or if you so request and this request is not contrary to legal or judicial restrictions.</p>
<p>8. YOUR RIGHTS You have the possibility to exercise the following rights free of charge for all data related to your person</p>
<p>The right to be informed about the origin, disclosure, purpose of collection and use, the planned duration of storage and the type of processing The right to rectification of your data, for example correction, reduction or addition. The right to restriction of processing with regard to the duration of use and in-kind, including revocation of authorizations already granted The right to erasure or blocking of those data relating to your person The right to lodge a complaint with the competent supervisory authority. In Switzerland, this is the Federal Data Protection and Information Commissioner (FDPIC). https://www.edoeb.admin.ch Where we are unable to allocate requests relating to points 1. to 4. beyond doubt, we reserve the right to assure ourselves of the identity of the person making the request and to demand copies of meaningful documents. To exercise rights 1. to 4. please contact the Data Protection Officer.</p>
<p>9. ADJUSTMENT OF PRIVACY POLICY As soon as we add, change or delete features or links on this website, we will adjust this privacy policy. The date of the last update is shown at the top of this section. The most recent Privacy Policy supersedes all previous versions.</p>
<p>10. COMPLETE Access to websites and online stores as well as the sending and receiving of e-mails is never absolutely secure. Numerous security agencies and intelligence services monitor the use of the Internet without judicial permission and collect information that is not related to a specific suspicion or crime.</p>
            </div>
        </div>
    )
}

export default PrivacyPolicy