import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';



const Footer = () => {



  return (

    <footer className="footer">
      <p className="black">
        <Link to="/privacypolicy" style={{ color: "black" }}>Privacy Policy </Link>
      </p>
    </footer>
  );
}

export default Footer;